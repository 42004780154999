.apply__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.apply__banner {
  background-image: url(../Images/form-bg.svg);
  min-height: 85vh;
}
.apply__banner-content {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  align-content: space-between;
  padding: 75px 0 40px 0;
  text-align: center;
}
.appply__banner-header {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
}
.apply__logo1 {
  width: 200px;
  margin: 0 auto;
  display: block;
}
.appply__banner-header span {
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
}
.apply__banner-text h2 {
  font-size: 55px;
  color: #ffffff;
  font-family: "Fredoka", sans-serif;
}
.apply__banner-text p {
  margin-top: 20px;
  font-size: 20px;
  color: #ffffff;
}
/* Form */
.apply__form {
  width: 80%;
  margin: 0 auto;
  padding: 75px 0 0 0;
}
.apply__form-header {
  margin-bottom: 30px;
}
.apply__form-header h3 {
  font-size: 35px;
  color: #1b093d;
  font-family: "Fredoka", sans-serif;
}
.apply__form-group {
  margin-bottom: 25px;
}
.apply__form-group label,
.apply__form-group input[type="text"],
.apply__form-group select,
.apply__form-group textarea {
  display: block;
}
.apply__form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #222222;
  font-weight: 500;
}
.apply__form-group input:not([type="radio"]),
.apply__form-group select,
.apply__form-group textarea {
  border: 1px solid #999999;
  padding: 12px 5px;
  border-radius: 5px;
  outline: none;
  width: 100%;
}
.apply__form-group textarea {
  min-height: 80px;
}
.apply__form-sub-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.apply__input-group {
  margin-top: 20px;
}
.apply__form-radio {
  display: flex;
  align-items: center;
}
.apply__form-radio span {
  margin-left: 8px;
}
.apply__form-radio input {
  width: 18px;
  height: 18px;
}
.apply__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.apply__btns button {
  margin-right: 0;
}
.hide-btn {
  display: none;
}
.apply__banner-footer {
  display: flex;
  flex-direction: column;
}
.ap__progress-steps {
  display: flex;
}
.ap__progress-step {
  width: 100%;
  padding: 5px 2px;
  font-size: 14px;
  font-weight: 500;
  color: #1b093d;
  text-align: center;
}
.ap__progress-step:not(:last-child) {
  border-right: 3px solid #eeeeee;
}
.err-input {
  border-color: red !important;
}
.err-radio {
  color: red !important;
}
.ap__progress {
  width: 100%;
  height: 15px;
  -webkit-appearance: none;
  appearance: none;
  margin-top: 5px;
}
.ap__progress::-webkit-progress-bar {
  background-color: #eeeeee;
  border-radius: 10px;
}
.ap__progress::-webkit-progress-value {
  border-radius: 10px;
  background-color: #1b093d;
}
.learning__modes {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.learning__modes span {
  font-size: 14px;
}
/* Form Message */
.form-message__container {
  margin-top: 100px;
  padding: 20px 0;
  text-align: center;
}
.form-message__container img {
  width: 80%;
}
.form-message__container h3 {
  color: #1b093d;
}
.form-message__container p {
  color: #323232;
  line-height: 1.8;
  margin-top: 10px;
}
.form-message__container a {
  color: #ffa41d;
  font-weight: 500;
  margin-top: 20px;
  display: block;
}
/* @media */
@media (max-width: 1300px) {
  .apply__banner-content {
    width: 90%;
    padding: 20px 0 40px 0px;
  }
  .apply__form {
    width: 90%;
    padding: 20px 0 0 0;
  }
}
@media (max-width: 990px) {
  .apply__grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .apply__banner {
    min-height: 100%;
    padding: 50px 0;
  }
  .apply__banner-content {
    width: 90%;
    display: block;
    padding: 40px 0;
  }
  .apply__banner-text {
    margin-bottom: 60px;
  }
  .appply__banner-header {
    margin-bottom: 40px;
    /* display: none; */
  }
  .apply__form {
    width: 90%;
  }
  .apply__form-sub-group {
    grid-template-columns: repeat(1, 1fr);
  }
  .apply__banner-text h2 {
    font-size: 45px;
  }
  .apply__banner-text p {
    font-size: 18px;
  }
}

@media (max-width: 766px) {
  .apply__banner {
    min-height: 100%;
    padding: 0;
  }
  .apply__banner-text h2 {
    font-size: 40px;
  }
  .apply__banner-text p {
    font-size: 16px;
  }
  .apply__form {
    padding: 20px 0 0 0;
  }
}
@media (max-width: 500px) {
  .apply {
    min-width: 300px;
  }
  .apply__banner-content {
    padding: 10px 0 30px 0;
  }
  .ap__progress-steps {
    display: none;
  }
  .appply__banner-header img {
    width: 150px;
  }
  .appply__banner-header {
    margin-top: 0;
  }
  .apply__banner-text {
    margin-bottom: 40px;
  }
  .apply__banner-text h2 {
    font-size: 32px;
  }
  .apply__banner-text p {
    font-size: 14px;
  }
}
